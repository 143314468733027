import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyle = makeStyles({
  root: {
    flex: 'auto',
    position: 'relative',
    '& > div': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
});

export default function FlexAuto(props) {
  const { className = '', ...other } = props;
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={className} {...other}>
        {props.children}
      </div>
    </div>
  );
}

FlexAuto.propTypes = {
  className: PropTypes.string,
};
