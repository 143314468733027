import React, { useCallback, useRef, useState, Fragment, useEffect } from 'react';
import { IconButton, Button, Grow, Popper, Paper, ClickAwayListener, MenuList } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function NavDropDown(props) {
  const { icon, title, isMobile, closePopover } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const openClick = useCallback(() => {
    !open && setOpen(true);
  }, [setOpen, open]);

  useEffect(() => {
    if (closePopover) {
      setOpen(false);
    }
  }, [closePopover]);

  return (
    <div style={{ display: 'inline-block' }}>
      {icon && !title ? (
        <IconButton ref={ref} aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={openClick}>
          {icon}
        </IconButton>
      ) : (
        <Button>{title}</Button>
      )}
      <Popper open={open} anchorEl={ref.current} placement={'bottom-end'} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            /*style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}*/
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClick}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onClick={handleClick}>
                  {props.children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

NavDropDown.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
};
