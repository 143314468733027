import React, { useCallback, useState } from 'react';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { selectGunthyWallet } from 'redux/selectors';
import { invitePeople } from 'api';

export default function InvitationButton() {
  const gunthy_wallet = useSelector(selectGunthyWallet);
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleInviatation = useCallback(
    event => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const data = {
        email: formData.get('email').trim(),
        gunthy_wallet: formData.get('gunthy_wallet'),
      };
      invitePeople(data)
        .then(res => {
          console.log('invite people', res);
          handleClose();
        })
        .catch(() => {});
    },
    [handleClose],
  );

  return (
    <>
      {/*<ColorButton variant="outlined" onClick={handleOpen} style={{ marginRight: 4 }}>
        Invite
  </ColorButton>*/}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Invite people to GUNBOT.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please invite people to Gunbot. If the inviated user buys gunbot, you will receive commissions.
          </DialogContentText>
          <Box component={'form'} mt={4} onSubmit={handleInviatation} tabIndex={0}>
            <Box mb={3}>
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="gunthy_wallet"
                label="Your Gunthy wallet"
                name="gunthy_wallet"
                type="text"
                value={gunthy_wallet}
                inputProps={{
                  readOnly: true,
                }}
                tabIndex={0}
              />
            </Box>
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="email"
              autoFocus
              tabIndex={0}
            />
            <Box textAlign={'right'} mt={3} mb={1}>
              <Button onClick={handleClose} style={{ marginRight: 16 }} tabIndex={2}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" tabIndex={0}>
                Invite
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const ColorButton = withStyles(theme => ({
  root: {
    color: blue[200],
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: alpha(blue[200], 0.6),
    '&:hover': {
      borderColor: blue[200],
      backgroundColor: alpha(blue[200], 0.08),
    },
  },
}))(Button);
