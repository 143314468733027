import _ from 'lodash';
import Api from 'api/index';

async function GetUserTemplates() {
  let userTemplates = {};
  let finalResult = {};
  async function getData() {
    const result = await Api.getCustomStratEditors();
    return result;
  }
  userTemplates = await getData();

  if (userTemplates.status === 'success') {
    finalResult = userTemplates.templates;
  }
  return finalResult;
}

export default async function () {
  return GetUserTemplates().then(data => {
    return data;
  });
}
