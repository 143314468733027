import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

export default withStyles(() => ({
  outlinedSecondary: {
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255)',
    },
  },
  label: {
    textTransform: 'none',
  },
}))(Button);
