import { deepCompare } from 'assets/js/common';
import _, { isNil } from 'lodash';
import $scope from 'consts/scope';

export default function healMissingStrategySettings(strategies) {
  if (Object.keys(strategies).length < 1) {
    return false;
  }

  let healedStrategies = {};
  const defaultParams = $scope.defaultStrat;
  const defaultParamsMM = $scope.defaultStratMM;
  const defaultParamsBR = $scope.defaultStratBR;
  const fullDefaultsMM = { ...defaultParams, ...defaultParamsMM };
  const fullDefaultsBR = { ...defaultParams, ...defaultParamsBR };

  Object.keys(strategies).forEach(stratName => {
    if (strategies[stratName].isUTA) {
      healedStrategies[stratName] = strategies[stratName];
    } else if (
      strategies[stratName].BUY_METHOD.toLowerCase() === 'market_maket' ||
      strategies[stratName].SELL_METHOD.toLowerCase() === 'market_maker'
    ) {
      healedStrategies[stratName] = { ...fullDefaultsMM, ...strategies[stratName] };
    } else if (isNil(strategies[stratName].bitRage) || strategies[stratName].bitRage === false) {
      healedStrategies[stratName] = { ...defaultParams, ...strategies[stratName] };
    } else if (strategies[stratName].bitRage === true) {
      healedStrategies[stratName] = { ...fullDefaultsBR, ...strategies[stratName] };
    }
  });

  if (!deepCompare(strategies, healedStrategies)) {
    return healedStrategies;
  } else {
    return false;
  }
}
