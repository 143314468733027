import * as React from 'react';

class ErrorHandling extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      // Error path
      return (
        <div
          style={{
            backgroundColor: '#272727',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <h1 style={{ color: 'white', textAlign: 'center' }}>Oops, something went wrong</h1>
          <p style={{ color: 'white', textAlign: 'center' }}>Reload the page to continue</p>
          <button
            style={{
              backgroundColor: '#f0b90b',
              color: 'black',
              border: 'none',
              padding: '10px 20px',
              cursor: 'pointer',
            }}
            onClick={() => window.location.reload()}
            onMouseEnter={e => {
              e.currentTarget.style.backgroundColor = '#a88107';
            }}
            onMouseLeave={e => {
              e.currentTarget.style.backgroundColor = '#f0b90b';
            }}
          >
            Reload page
          </button>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorHandling;
