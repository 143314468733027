import { deepCompare } from 'assets/js/common';
import _, { isNil } from 'lodash';
import $scope from 'consts/scope';

export default function healStrategyCasing(data, type) {
  if (type === 'pairs') {
    let healedPairs = JSON.parse(JSON.stringify(data));
    Object.keys(data).forEach(exchange => {
      Object.keys(data[exchange]).forEach(pair => {
        healedPairs[exchange][pair].strategy = data[exchange][pair].strategy.toLowerCase();
        if (!_.isNil(data[exchange][pair].override?.BUY_METHOD)) {
          healedPairs[exchange][pair].override.BUY_METHOD = data[exchange][pair].override.BUY_METHOD.toLowerCase();
        }
        if (!_.isNil(data[exchange][pair].override?.SELL_METHOD)) {
          healedPairs[exchange][pair].override.SELL_METHOD = data[exchange][pair].override.SELL_METHOD.toLowerCase();
        }
      });
    });

    if (!_.isEqual(data, healedPairs)) {
      return healedPairs;
    } else {
      return false;
    }
  } else {
    const oldStrategies = JSON.parse(JSON.stringify(data));
    let healedStrategies = {};
    Object.keys(oldStrategies).forEach(stratName => {
      healedStrategies[stratName.toLowerCase()] = oldStrategies[stratName];
      if (oldStrategies[stratName].isUTA) return;
      healedStrategies[stratName.toLowerCase()].BUY_METHOD =
        healedStrategies[stratName.toLowerCase()].BUY_METHOD.toLowerCase();
      healedStrategies[stratName.toLowerCase()].SELL_METHOD =
        healedStrategies[stratName.toLowerCase()].SELL_METHOD.toLowerCase();
    });
    if (!_.isEqual(data, healedStrategies)) {
      return healedStrategies;
    } else {
      return false;
    }
  }
}
