import _ from 'lodash';

export default function getLicenseType(config) {
  // licenses reduced to these types: 'one', 'standard', 'standardtv', 'pro', 'protv' 'ultimate', 'br', 'mm', used only to display relevant items in the gui
  try {
    const isDemo = config.GUI?.demo;
    const demoLevel = config.GUI?.demoLevel || 'mm';
    if (isDemo) {
      return demoLevel;
    }
    let licenseDetails = JSON.parse(localStorage.getItem('licenseDetails'));
    let fallback = {
      type: 'standard',
      tokenBalance: 1000,
      mm: false,
      tradeview: false,
    };
    const hasWallet = !_.isNil(config.bot.gunthy_wallet);
    let licenseType = 'one';

    if (hasWallet && _.isNil(licenseDetails)) {
      licenseType = 'standard';
      licenseDetails = fallback;
    } else if (hasWallet && !_.isNil(licenseDetails?.type) && licenseDetails?.type.toLowerCase().includes('standard')) {
      licenseType = 'standard';
    } else if (
      (hasWallet && !_.isNil(licenseDetails?.type) && licenseDetails?.type.toLowerCase() === 'pro') ||
      licenseDetails?.type.toLowerCase() === 'promopro'
    ) {
      licenseType = 'pro';
    } else if (hasWallet && !_.isNil(licenseDetails?.type) && licenseDetails?.type.toLowerCase() === 'promoultimate') {
      licenseType = 'promoultimate';
    } else if (hasWallet && !_.isNil(licenseDetails?.type) && licenseDetails?.type.toLowerCase() === 'ultimate') {
      licenseType = 'ultimate';
    }

    if (hasWallet && !_.isNil(licenseDetails?.tokenBalance) && licenseDetails?.tokenBalance >= 12000) {
      licenseType = 'br';
    }
    if (hasWallet && !_.isNil(licenseDetails?.tokenBalance) && licenseDetails?.tokenBalance >= 24000) {
      licenseType = 'mm';
    }
    if (hasWallet && !_.isNil(licenseDetails?.mm) && licenseDetails?.mm) {
      licenseType = 'mm';
    }
    if (hasWallet && licenseType === 'standard' && !_.isNil(licenseDetails?.tradeview) && licenseDetails?.tradeview) {
      licenseType = 'standardtv';
    }
    if (hasWallet && licenseType === 'pro' && !_.isNil(licenseDetails?.tradeview) && licenseDetails?.tradeview) {
      licenseType = 'protv';
    }
     // Check for the new license types
     const licenseTypeLower = licenseDetails?.type.toLowerCase();
     if (hasWallet && !_.isNil(licenseTypeLower)) {
       if (licenseTypeLower === 'monthlystandard') {
         licenseType = 'monthlyStandard';
       } else if (licenseTypeLower === 'monthlypro') {
         licenseType = 'monthlyPro';
       } else if (licenseTypeLower === 'monthlyultimate') {
         licenseType = 'monthlyUltimate';
       } else if (licenseTypeLower === 'yearlystandard') {
         licenseType = 'yearlyStandard';
       } else if (licenseTypeLower === 'yearlypro') {
         licenseType = 'yearlyPro';
       } else if (licenseTypeLower === 'yearlyultimate') {
         licenseType = 'yearlyUltimate';
       }
     }
 
    return licenseType;
  } catch (error) {
    console.log(error);
    console.log('Error getting license status, assuming Gunbot ONE license type');
    return 'one';
  }
}
